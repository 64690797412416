import React, { useEffect, useState } from 'react';
import { Link, graphql } from 'gatsby';
import queryString from 'query-string';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CGeneralMedia,
  CSectTitle,
  CSectTitle03,
  CDefinition,
  LOgata,
  CHeroImg,
} from '../../../components/_index';
import shopGet from '../../../utils/shop-get';

// markup
const SubPage = ({ data, location }: any) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const { contentId, draftKey } = queryString.parse(location.search);
  const [shopdata, setShopData] = useState<any | null>(shopGet('keikaen'));
  if (contentId) {
    useEffect(() => {
      fetch(
        `https://${process.env.MICRO_CMS_SERVICE_DOMAIN}.microcms.io/api/v1/shop_srph/${contentId}?draftKey=${draftKey}`,
        {
          headers: {
            'X-MICROCMS-API-KEY': process.env.MICRO_CMS_API_KEY || '',
          },
        }
      )
        .then((res) => res.json())
        .then((res) => setShopData(res));
    }, []);
  }

  return (
    <Layout isKeikaen={true}>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="exLarge"
        data={{
          logo: {
            src: '/assets/images/restaurants/keikaen/logo_keikaen.svg',
            alt: '桂花苑',
          },
          title: {
            ja: '',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/keikaen/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/keikaen/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'レストラン',
                path: '/restaurants/',
              },
            ],
            current: {
              label: frontmatter?.title.includes('-')
                ? frontmatter?.title.split('-')[0]
                : frontmatter?.title,
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect keikaen">
        <LWrap>
          <CSectTitle03
            type="en"
            title={{ sub: '桂花苑の魅力', main: 'ABOUT' }}
          />
          <h2 className="c_headingLv2">
            本格的な中国料理と創造的な空間が織りなす
            <br />
            「エキサイティングな食体験」
          </h2>
          <CHeroImg
            img={{
              src: '/assets/images/restaurants/keikaen/img_keikaen.png',
              alt: '',
            }}
          />
          <div className="p_keikaen">
            <p className="title">
              珠玉の中国料理を
              <br />
              もっと、愉しく。
            </p>
            <p className="content">
              「⾷は広州にあり」と⾔われるほど⼭海の幸に恵まれた美⾷の中⼼地、広東。
              <br />
              桂花苑がお届けするのは素材の美味しさを活かした、シンプルな味わいが特徴の広東料理です。
              <br />
              幅広い年代のお客様に愛されるやさしい味わいを⼼ゆくまで。
            </p>
          </div>
          <CHeroImg
            img={{
              src: '/assets/images/restaurants/keikaen/img_keikaen02.png',
              alt: '',
            }}
          />
          <div className="p_keikaen">
            <p className="title">
              至福のひとときを
              <br />
              もっと、贅沢に。
            </p>
            <p className="content">
              宮城県の豊かな海・⼭・⼤地で育まれた豊富な⾷材などを活かして、季節ごとの恵みを感じられる中国料理。
              <br />
              仙台リゾートで気軽に飲茶をたのしむランチと、ゆっくり時間が流れるディナータイムをお過ごしください。
            </p>
          </div>
        </LWrap>
      </section>
      <section className="l_sect p_menu u_bgWhite">
        <LWrap>
          <CGeneralMedia
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/keikaen/img_keikaen03.png',
                  alt: '',
                },
                title: (
                  <>
                    <span className="font-fCG">RESTAURANT</span>
                    <p>レストラン</p>
                  </>
                ),
                text: (
                  <>
                    <p>
                      仙台市内を⼀望できるホテル最上階のチャイニーズダイニング。
                      <br />
                      リゾートホテルならではの四季折々の美しい景⾊と、美味しい料理でおもてなしいたいします。
                    </p>
                  </>
                ),
                btn: {
                  label: 'レストラン',
                  link: {
                    href: '/restaurants/keikaen/restaurants/',
                  },
                  color: 'borderBlack',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/keikaen/img_keikaen04.png',
                  alt: '',
                },
                title: (
                  <>
                    <span className="font-fCG">PRIVATE ROOM</span>
                    <p>個室</p>
                  </>
                ),
                text: (
                  <>
                    <p>
                      結納・顔合わせなど様々なシーンに対応できる2部屋の個室をご⽤意。ご家族、ご友⼈、⼤切な⽅を招いて、
                      <br />
                      気兼ねなくカジュアルに楽しめる中国料理のおもてなしをご単横ください。
                    </p>
                  </>
                ),
                btn: {
                  label: '個室',
                  link: {
                    href: '/restaurants/keikaen/restaurants/#private-room-section',
                  },
                  color: 'borderBlack',
                },
              },
              
              {
                img: {
                  src: '/assets/images/restaurants/keikaen/img_keikaen07.png',
                  alt: '',
                },
                title: (
                  <>
                    <span className="font-fCG">貸切会⾷</span>
                  </>
                ),
                text: (
                  <>
                    <p>
                    リゾートホテルの最上階を貸し切って、気兼ねない少⼈数の会⾷から、⼤切なお客様を招いてのおもてなし、
                    <br />
                    ビジネスシーンでの会⾷などお得で豊富なプランを数々ご⽤意。
                    <br />
                    お客様の希望を叶える貸切会⾷をお⼿伝いいたします。
                    </p>
                  </>
                ),
                btn: {
                  label: '貸切会⾷',
                  link: {
                    href: '/restaurants/keikaen/privateparty/',
                  },
                  color: 'borderBlack',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/keikaen/img_keikaen05.png',
                  alt: '',
                },
                title: (
                  <>
                    <span className="font-fCG">WEDDING</span>
                    <p>結婚式</p>
                  </>
                ),
                text: (
                  <>
                    <p>
                      桂花苑ならではの本格的な中国料理を囲んで⼤切な⽅々との会話を愉しむ。ご家族やご友⼈だけでのアットホームな結婚式を叶えたい⽅におすすめです。
                    </p>
                  </>
                ),
                btn: {
                  label: '結婚式',
                  link: {
                    href: '/restaurants/keikaen/wedding/',
                  },
                  color: 'borderBlack',
                },
              },
            ]}
            exClass="c_generalMedia_title"
          />
        </LWrap>
      </section>
      <section className="l_sect u_bgWhite">
        <LWrap>
          <CSectTitle
            title={{
              ja: '営業時間｜ご利用案内',
              en: <>GUIDE</>,
            }}
          />
          <CDefinition
            exClass="u_mb30"
            data={shopdata.information.map((item: any) => {
              return {
                title:
                  item.keyname &&
                  item.keyname.split('\n').map((t: string) => (
                    <>
                      {t}
                      <br />
                    </>
                  )),
                text: (
                  <div
                    className="wysiwyg"
                    dangerouslySetInnerHTML={{
                      __html: `${item.value}`,
                    }}
                  />
                ),
              };
            })}
          />
          <ul className="c_noteList">
            <li>掲載の内容は予告なく変更する場合があります。</li>
            <li>画像はイメージです。</li>
            <li>表示価格は消費税、サービス料込みの価格です。</li>
            <li>
              メニュー内容は仕入れの状況によって変更になる場合がございます。
            </li>
          </ul>
        </LWrap>
      </section>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
